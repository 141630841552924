ion-icon {
  color: #ffff;
}

.social-media-group {
  justify-content: center;
}

.new_social_media{
  justify-content: left !important;
  padding: 0% !important;
  margin: 0px !important;
  margin-left: 4px !important;
}

.sportbook-img {
  height: 36px;
  width: 36px;
  cursor: pointer;
  background: #304B8B;
  border-radius: 50px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 920px) {
    height: 32px;
    width: 32px;
    margin-top: 2px;
    margin-bottom: 5px;
  }

  &.fb-icon {
    background: #1877f2;

    img {
      position: relative;
      left: 3px;
    }
  }

  &.twitter-icon {
    background: #01a2f0;

    img {
      position: relative;
      top: 1px;
    }
  }
  & .telegram-icon {
    width: 42px;
  }

  &.ig-icon {
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);;
  }
}

@media (max-width: 1124px) {
  .mobile-social-media-group {
    background: var(--footer-vertical-gradient);
    display: none;
  }
  .social-media-group {
    width: 100%;
    margin: 7px;
    z-index: auto;
  }
}

